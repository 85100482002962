import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import store from './store';
import Error404 from 'containers/errors/error404';
import Home from 'containers/pages/home';
import { Provider } from 'react-redux';
import Nfqs from 'containers/pages/Nuestra Federación/Nfqs';
import Directorio from 'containers/pages/Nuestra Federación/Directorio';
import Estatutos from 'containers/pages/Nuestra Federación/Estatutos';
import EstructuraFede from 'containers/pages/Nuestra Federación/EstructuraFede';
import PlanEstrategico from 'containers/pages/Nuestra Federación/PlanEstrategico';
import NormatividadFDF from 'containers/pages/Fondo de fomento/NormatividadFDF';
import ServiciosFDF from 'containers/pages/Fondo de fomento/ServiciosFDF';
import Comercial from 'containers/pages/Normatividad/Comercial';
import Ambiental from 'containers/pages/Normatividad/Ambiental';
import Sanitarias from 'containers/pages/Normatividad/Sanitarias';
import SomosFDF from 'containers/pages/Fondo de fomento/SomosFDF';
import ProgramasFDF from 'containers/pages/Fondo de fomento/ProgramasFDF';
import AtencionFDF from 'containers/pages/Fondo de fomento/AtencionFDF';
import EstructuraOrganica from 'containers/pages/Fondo de fomento/EstructuraOrganica';
import ProcesosRecaudoFDF from 'containers/pages/Fondo de fomento/ProcesosRecaudoFDF';
import CalendarioEventosFDF from 'containers/pages/Fondo de fomento/CalendarioEventosFDF';
import QuejasReclamos from 'containers/pages/Fondo de fomento/QuejasReclamos';
import Laboral from 'containers/pages/Normatividad/Laboral';
import MecanismoContacto from 'containers/pages/Ley de Transparencia/MecanismosContacto';
import Informaciondeinteres from 'containers/pages/Ley de Transparencia/InformaciondeInteres';
import Planeacion from 'containers/pages/Ley de Transparencia/Planeacion';
import OportunidadesComerciales from 'containers/pages/Area comercial/OportunidadesComerciales';
import Estructuraorgánicaytalentohumano from 'containers/pages/Ley de Transparencia/Estructuraorganicaytalentohumano';
import Normatividad from 'containers/pages/Ley de Transparencia/Normatividad';
import ProyeccionNegocio from 'containers/pages/Area comercial/ProyecciónNegocio';
import Control from 'containers/pages/Ley de Transparencia/Control';
import Aexcid from 'containers/pages/Convenios/Aexid';
import Finagro from 'containers/pages/Convenios/Finagro';
import NamaPanela from 'containers/pages/Convenios/NamaPanela';
import PreguntasFrecuentes from 'containers/pages/Fondo de fomento/PreguntasFrecuentes';
import HerramientesInter from 'containers/pages/Area comercial/HerramientasInter';
import ObjetivoTecnica from 'containers/pages/Area tecnica/ObjetivoTecnica';
import AsistenciaTecnica from 'containers/pages/Area tecnica/AsistenciaTecnica';
import SeguridadSocial from 'containers/pages/Area tecnica/SeguridadSocial';
import CapitalSocial from 'containers/pages/Area tecnica/CapitalSocial';
import BibliografiaTecnica from 'containers/pages/Area tecnica/BibliografiaTecnica';
import ObjetivoComunicaciones from 'containers/pages/Area comunicaciones/ObjetivoComunicaciones';
import PromocionConsumo from 'containers/pages/Area comunicaciones/PromocionConsumo';
import PanelaKids from 'containers/pages/Area comunicaciones/PanelaKids';
import ObjetivoSocio from 'containers/pages/Area socioempresarial/ObjetivoSocio';
import ConstruccionCapital from 'containers/pages/Area socioempresarial/ConstruccionCapital';
import SeguridadySalud from 'containers/pages/Area socioempresarial/SeguridadySalud';
import NormatividadSocio from 'containers/pages/Area socioempresarial/NormatividadSocio';
import Contratacion from 'containers/pages/Ley de Transparencia/Contratacion';
import Presupuesto from 'containers/pages/Ley de Transparencia/Presupuesto';
import TramitesyServicios from 'containers/pages/Ley de Transparencia/TramitesyServicios';
import InstrumentosDeGestion from 'containers/pages/Ley de Transparencia/InstrumentosDeGestion';
import TransparenciaPasiva from 'containers/pages/Ley de Transparencia/TransparenciaPasiva';
import AccesibilidadWeb from 'containers/pages/Ley de Transparencia/AccesibilidadWeb';
import HabeasData from 'containers/pages/Ley de Transparencia/HabeasData';
import NoticiasBlog from 'containers/pages/NoticiasBlog';
import NoticiasDetail from 'containers/pages/NoticiasDetail';
import BoletinTendencias from 'containers/pages/BoletinTendencias';
import BoletinNews from 'containers/pages/BoletinNews';
import Contacto from 'containers/pages/Contacto';
import OfertasEmpleo from 'containers/pages/Fondo de fomento/OfertasEmpleo';
import Convocatorias from 'containers/pages/Fondo de fomento/Convocatorias';
import OfertaPanelera from 'containers/pages/Sistemas de informacion/OfertaPanelera';
import PreciosMercados from 'containers/pages/Sistemas de informacion/PreciosMercados';
import Sig from 'containers/pages/Sistemas de informacion/Sig';
import TipsPaneleros from 'containers/pages/Sistemas de informacion/TipsPaneleros';
import GlosarioPanelero from 'containers/pages/Sistemas de informacion/GlosarioPanelero';
import Biblioteca from 'containers/pages/Sistemas de informacion/Biblioteca';
import PreciosBlog from 'containers/pages/PreciosBlog';
import { Helmet, HelmetProvider } from 'react-helmet-async';
function App() {
  return (
    <HelmetProvider>
      <Helmet></Helmet>
      <Provider store={store}>
        <Router>
          <Routes>
            {/*Error Display */}
            <Route path="*" element={<Error404 />} />

            {/*Home Display */}
            <Route path="/" element={<Home />} />
            {/*Home Display */}
            <Route path="/noticias" element={<NoticiasBlog />} />

            {/*Contacto Display */}
            <Route path="/contacto" element={<Contacto />} />

            {/*Detail Display */}
            <Route path="/noticias/:slug" element={<NoticiasDetail />} />


            {/*Tendencias Display */}

            <Route path="/tendencias" element={<BoletinTendencias />} />

            {/*Boletin de precios Display */}

            <Route path="/precios" element={<PreciosBlog />} />


            {/*News Display */}

            <Route path="/news" element={<BoletinNews />} />


            {/*nfqa Display */}
            <Route path="/NuestraFederacion/quienessomos" element={<Nfqs />} />

            {/*Directorio Display */}
            <Route path="/NuestraFederacion/directorio" element={<Directorio />} />

            {/*Estatutos Display */}
            <Route path="/NuestraFederacion/estatutos" element={<Estatutos />} />

            {/*EstructuraFede Display */}
            <Route path="/NuestraFederacion/EstructuraFedepanela" element={<EstructuraFede />} />

            {/*PlanEstrategico Display */}
            <Route path="/NuestraFederacion/PlanEstrategico" element={<PlanEstrategico />} />





            {/*PlanEstrategico Display */}
            <Route path="/FondodeFomento/Normatividad" element={<NormatividadFDF />} />
            {/*PlanEstrategico Display */}
            <Route path="/FondodeFomento/Servicios" element={<ServiciosFDF />} />
            <Route path="/FondodeFomento/QuienesSomos" element={<SomosFDF />} />
            <Route path="/FondodeFomento/Programas" element={<ProgramasFDF />} />
            <Route path="/FondodeFomento/AtencionCiudadano" element={<AtencionFDF />} />
            <Route path="/FondodeFomento/EstructuraOrganica" element={<EstructuraOrganica />} />
            <Route path="/FondodeFomento/ProcesosRecaudo" element={<ProcesosRecaudoFDF />} />
            <Route path="/FondodeFomento/CalendarioEventos" element={<CalendarioEventosFDF />} />
            <Route path="/FondodeFomento/QuejasReclamos" element={<QuejasReclamos />} />
            <Route path="/FondodeFomento/PreguntasFrecuentes" element={<PreguntasFrecuentes />} />
            <Route path="/FondodeFomento/OfertasEmpleo" element={<OfertasEmpleo />} />
            <Route path="/FondodeFomento/Convocatorias" element={<Convocatorias />} />






            {/*Normatividad*/}
            <Route path="/Normatividad/Comercial" element={<Comercial />} />
            <Route path="/Normatividad/Ambiental" element={<Ambiental />} />
            <Route path="/Normatividad/Sanitarias" element={<Sanitarias />} />
            <Route path="/Normatividad/Laboral" element={<Laboral />} />



            {/*Ley Transparecia*/}
            <Route path="/LeyTransparencia/MecanismosContacto" element={<MecanismoContacto />} />
            <Route path="/LeyTransparencia/Informaciondeinteres" element={<Informaciondeinteres />} />
            <Route path="/LeyTransparencia/Estructuraorgánicaytalentohumano" element={<Estructuraorgánicaytalentohumano />} />
            <Route path="/LeyTransparencia/Normatividad" element={<Normatividad />} />
            <Route path="/LeyTransparencia/Planeacion" element={<Planeacion />} />
            <Route path="/LeyTransparencia/Control" element={<Control />} />
            <Route path="/LeyTransparencia/Contratacion" element={<Contratacion />} />
            <Route path="/LeyTransparencia/Presupuesto" element={<Presupuesto />} />
            <Route path="/LeyTransparencia/TramitesyServicios" element={<TramitesyServicios />} />
            <Route path="/LeyTransparencia/InstrumentosDeGestion" element={<InstrumentosDeGestion />} />
            <Route path="/LeyTransparencia/TransparenciaPasiva" element={<TransparenciaPasiva />} />
            <Route path="/LeyTransparencia/AccesibilidadWeb" element={<AccesibilidadWeb />} />
            <Route path="/LeyTransparencia/HabeasData" element={<HabeasData />} />



            {/*Convenios*/}

            <Route path="/Convenios/AexidyFHRE" element={<Aexcid />} />
            <Route path="/Convenios/Finagro" element={<Finagro />} />
            <Route path="/Convenios/NamaPanela" element={<NamaPanela />} />

            {/*Area Tecnica*/}

            <Route path="/AreaTecnica/Objetivo" element={<ObjetivoTecnica />} />
            <Route path="/AreaTecnica/AsistenciaTecnica" element={<AsistenciaTecnica />} />
            <Route path="/AreaTecnica/SeguridadSocial" element={<SeguridadSocial />} />
            <Route path="/AreaTecnica/CapitalSocial" element={<CapitalSocial />} />
            <Route path="/AreaTecnica/BibliografiaTecnica" element={<BibliografiaTecnica />} />


            {/*Area comercial*/}

            <Route path="/AreaComercial/OportunidadesComerciales" element={<OportunidadesComerciales />} />
            <Route path="/AreaComercial/Herramientas" element={<HerramientesInter />} />
            <Route path="/AreaComercial/ProyeccionNegocio" element={<ProyeccionNegocio />} />

            {/*Area Comunicaciones*/}

            <Route path="/AreaComunicaciones/Objetivo" element={<ObjetivoComunicaciones />} />
            <Route path="/AreaComunicaciones/PromocionConsumo" element={<PromocionConsumo />} />
            <Route path="/AreaComunicaciones/PanelaKids" element={<PanelaKids />} />
            {/*Area Socio Empresarial*/}

            <Route path="/AreaSocioEmpresarial/Objetivo" element={<ObjetivoSocio />} />
            <Route path="/AreaSocioEmpresarial/ConstruccionCapital" element={<ConstruccionCapital />} />
            <Route path="/AreaSocioEmpresarial/SeguridadySalud" element={<SeguridadySalud />} />
            <Route path="/AreaSocioEmpresarial/NormatividadSocio" element={<NormatividadSocio />} />

            {/*Area Sistemas de información*/}

            <Route path="/SistemasInformacion/OfertaPanelera" element={<OfertaPanelera />} />
            <Route path="/SistemasInformacion/PreciosMercados" element={<PreciosMercados />} />
            <Route path="/SistemasInformacion/SIg" element={<Sig />} />
            <Route path="/SistemasInformacion/TipsPaneleros" element={<TipsPaneleros />} />
            <Route path="/SistemasInformacion/Glosario" element={<GlosarioPanelero />} />
            <Route path="/SistemasInformacion/Biblioteca" element={<Biblioteca />} />


















          </Routes>
        </Router>
      </Provider>
    </HelmetProvider>

  );
}

export default App;
