export const GET_NEWS_LIST_SUCCESS = 'GET_NEWS_LIST_SUCCESS';
export const GET_NEWS_LIST_FAIL = 'GET_NEWS_LIST_FAIL';

export const GET_NEWS_HOME_SUCCESS = 'GET_NEWS_HOME_SUCCESS';
export const GET_NEWS_HOME_FAIL = 'GET_NEWS_HOME_FAIL';

export const GET_NEW_DETAIL_SUCCESS = 'GET_NEW_DETAIL_SUCCESS';
export const GET_NEW_DETAIL_FAIL = 'GET_NEW_DETAIL_FAIL';

export const GET_FILTERED_SUCCESS = 'GET_FILTERED_SUCCESS';
export const GET_FILTERED_FAIL = 'GET_FILTERED_FAIL';

