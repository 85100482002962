export const GET_BOLETINES_TENDENCIAS_SUCCESS = 'GET_BOLETINES_TENDENCIAS_SUCCESS';
export const GET_BOLETINES_TENDENCIAS_FAIL = 'GET_BOLETINES_TENDENCIAS_FAIL';

export const GET_BOLETINES_NEWS_SUCCESS = 'GET_BOLETINES_NEWS_SUCCESS';
export const GET_BOLETINES_NEWS_FAIL = 'GET_BOLETINES_NEWS_FAIL';

export const GET_BOLETINES_HOME_SUCCESS = 'GET_BOLETINES_HOME_SUCCESS';
export const GET_BOLETINES_HOME_FAIL = 'GET_BOLETINES_HOME_FAIL';

export const GET_PRICES_SUCCESS = 'GET_PRICES_SUCCESS';
export const GET_PRICES_FAIL = 'GET_PRICES_FAIL';

