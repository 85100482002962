export const GET_DIRECTORIO_SUCCESS = 'GET_DIRECTORIO_SUCCESS';
export const GET_DIRECTORIO_FAIL = 'GET_DIRECTORIO_FAIL';


export const GET_RECAUDO_SUCCESS = 'GET_RECAUDO_SUCCESS';
export const GET_RECAUDO_FAIL = 'GET_RECAUDO_FAIL';

export const GET_TECNICA_SUCCESS = 'GET_TECNICA_SUCCESS';
export const GET_TECNICA_FAIL = 'GET_TECNICA_FAIL';



